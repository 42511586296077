<template>
	<div class="container-fluid contain">
		<span class="title">Wallet</span><br/>
		<div class="row" style="margin-left: -20px">
			<div class="col-lg-10 col-mg-12" style="display: flex; flex-direction: column">
				<div class="search">
					<input class="search-input" placeholder="Search Wallet" v-model="keyword"/>
					<img src="@/assets/images/icon_search.png" style="width: 24px; float: right">
				</div>
				<div class="wallet-item" v-for="(item, index) in keyword? transactions: this.state.wallet.wallets" :key="index">
					<WalletItem :item="item"/>
				</div>
			</div>
			<div class="col-lg-2 col-mg-12">
				<div class="balance">
					<div style="font-weight: bold; font-size: 18px;">Balance</div>
					<div style="font-weight: bold; font-size: 35px; margin-top: -10px;">${{state.currentUser.balance}}
					</div>
				</div>
<!--				<button class="btn withdraw" type="button" data-toggle="modal">WITHDRAW-->
<!--				</button>-->
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import WalletItem from "../components/WalletItem";
  import {get} from 'lodash';
  import {json} from 'overmind'

  export default {
    components: {
      WalletItem
    },
    setup() {
      const data = reactive({
        transactions: [],
        get,
        keyword: null,
      });
      return {
        ...toRefs(data)
      }
    },
    beforeMount() {
      console.log(this.state.wallet.wallets)
      this.transactions = json(this.state.wallet.wallets);
    },
    watch: {
      keyword: function (keyword) {
        if (keyword.length !== 0) {
          const key = keyword.trim().toLowerCase();
          this.transactions = json(this.state.wallet.wallets).filter(c => {
            if (c.ad !== null) return c.ad.drinkName.toLowerCase().indexOf(key) >= 0;

            if (c && c.split && c.split.fullName.toLowerCase().indexOf(key) >= 0) return true;
            if (c && c.sentTo && c.sentTo.fullName.toLowerCase().indexOf(key) >= 0) return true;
            if (c && c.sentFrom && c.sentFrom.fullName.toLowerCase().indexOf(key) >= 0) return true;
            if (c && c.flute && c.flute.sentTo && c.flute.sentTo.fullName.toLowerCase().indexOf(key) >= 0) return true;

            return false
          });
        }
      }
    }
  }
</script>

<style lang="css" scoped>
	.search {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 12px 25px;
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-left-width: 0;
		border-right-width: 0;
		border-color: #c8c8c8;
		border-style: solid;
		margin-top: 22px;
		width: 100%;
	}
	
	.search-input {
		border: 0;
		width: 90%;
	}
	
	input.search-input:focus {
		outline-width: 0 !important;
	}
	
	.wallet-item {
		height: 85px;
		display: flex;
		align-items: center;
		padding: 0 10px;
		border-bottom-width: 1px;
		border-top-width: 0;
		border-left-width: 0;
		border-right-width: 0;
		border-style: solid;
		border-color: #97979717;
	}
	
	
	.balance {
		background: #E2E2E2;
		border: 1px solid #C7C7C7;
		box-sizing: border-box;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 120px;
		flex-direction: column;
	}
	
	.withdraw {
		background: #FFFFFF;
		border: 1px solid #000000;
		box-sizing: border-box;
		border-radius: 5px;
		height: 45px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		line-height: 17px;
		color: #010101;
		width: 100%;
		margin-top: 10px;
	}

</style>
