<template lang="html">
	<div class=" align-items-center justify-content-center" style="display: flex" v-if="!(!isDebit && isSent)">
		<div style="width: 56px; display: flex;" class="justify-content-center align-items-center">
			<img src="@/assets/images/logo_wallet.png" style="width: 50px; height: 50px;" v-if="isDebit"/>
			<img :src="get(item, 'sentFrom.avatar', null)" class="item-image" v-if="get(item, 'sentFrom.avatar', null)"/>
			<img src="@/assets/images/avatar-male-1.png" class="item-image" v-else-if="get(item, 'sentFrom.gender', 'Male') === 'Male'"/>
			<img src="@/assets/images/avatar-female-1.png" class="item-image" v-else-if="get(item, 'sentFrom.gender', 'Male') !== 'Male'"/>
			<img src="@/assets/images/ic_wallet_sent.png" style="position: absolute; right: 0; bottom: 0; width: 34px; height: 34px;" v-if="!isDebit && isSent"/>
			<!--            <img :src="(isCampaign || item.isRebate) ? get(item, 'ad.images.iPhone'): ((isFlute && !isSent && isAccepted && item.isRebate) && '@/assets/images/ic_no_user.png')"-->
			<!--                 style="width: 35px; height: 35px; width: 20px; background-color: #DDDDDD">-->
		</div>
		<div style="margin-left: 20px">
			<div style="font-size: 14px; font-weight: 500" v-if="isFlute && !item.isRebate">{{user.firstName ||
				user.fullName}}
			</div>
			<div style="font-size: 14px; font-weight: 500" v-if="isFlute && !isSent && isAccepted && item.isRebate && !isDelivery">
				{{item.campaign.name || ''}}
			</div>
			<div style="font-size: 14px; font-weight: 500" v-if="isCampaign">
				{{`${get(item, 'campaign.name', '')}`}}
			</div>
			<div style="font-size: 14px; font-weight: 500" v-if="isDebit">Flute Wallet</div>
			
			<div class="desc" v-if="isDebit && item.usedOnTab">{{`${get(item, 'venue.name', '')} @
				${mcreate.format('h:mma')}`}}
			</div>
			<div class="desc" v-if="isDebit && item.usedForSplit">{{`Split tab with ${get(item, 'split.fullName',
				'')}`}}
			</div>
			<div class="desc" v-if="isDebit && item.usedForGift">{{`Flute for ${get(item, 'flute.sentTo.firstName',
				'')}`}}
			</div>
			<div class="desc" v-if="isFlute && isPending">Pending...</div>
			<div class="desc" v-if="isFlute && isSent && isAccepted">Accepted your offer</div>
			<div class="desc" v-if="isFlute && isSent && isDeclined">Declined your offer</div>
			<div class="desc" v-if="isFlute && isSent && isExpired">Offer Expired</div>
			<div class="desc" v-if="isFlute && !isSent && isAccepted && !item.isRebate">You accepted</div>
			<div class="desc" v-if="isFlute && !isSent && isAccepted && item.isRebate">Instant Rebate</div>
			<div class="desc" v-if="isFlute && !isSent && isDeclined">You declined</div>
			<div class="desc" v-if="isDelivery">Delivery</div>
			<div class="desc" v-if="isCampaign && !isExpired && item.numberOfItemsRedeemed === 0 && item.numberOfItems === 1 && !isDelivery">
				{{`Good for ${item.numberOfItems} ${itemLabel}`}}
			</div>
			<div class="desc" v-if="isCampaign && !isExpired && item.numberOfItemsRedeemed === 0 && item.numberOfItems > 1 && !isDelivery">
				{{`Good for ${item.numberOfItems} ${itemLabelPlural}`}}
			</div>
			<div class="desc" v-if="isCampaign && !isExpired && item.numberOfItemsRedeemed > 0 && item.numberOfItems === 1 && !isDelivery">
				{{`Redeemed ${item.numberOfItemsRedeemed} of ${item.numberOfItems} ${itemLabel}`}}
			</div>
			<div class="desc" v-if="isCampaign && !isExpired && item.numberOfItemsRedeemed > 0 && item.numberOfItems > 1 && !isDelivery">
				{{`Redeemed ${item.numberOfItemsRedeemed} of ${item.numberOfItems} ${itemLabelPlural}`}}
			</div>
			<div class="desc" v-if="isCampaign && isExpired">{{'Drink Offer Expired'}}</div>
		</div>
		<div class="wallet-price">
			<div style="display: flex; flex-direction: row;" class="justify-content-start align-items-start">
				<div style="font-size: 15px; font-weight: bold" v-if="isCampaign && !isDelivery && remainderDrinks === 0">
					{{remainderDrinks}} {{itemLabelPlural}}
				</div>
				<div style="font-size: 15px; font-weight: bold" v-if="isCampaign && !isDelivery && remainderDrinks === 1">
					{{remainderDrinks}} {{itemLabel}}
				</div>
				<div style="font-size: 15px; font-weight: bold" v-if="isCampaign && !isDelivery && remainderDrinks >1">
					{{remainderDrinks}} {{itemLabelPlural}}
				</div>
				<div style="font-size: 17px; font-weight: bold; margin-top: -2px;" v-if="!sign">{{sign}}</div>
				<div style="font-size: 15px; font-weight: bold" v-if="!isCampaign">$</div>
				<div style="font-size: 15px; font-weight: bold;" v-if="!isCampaign">{{numeral(amount).format('0,0')}}</div>
				<div style="font-size: 13px; font-weight: bold; margin-top: -2px;" v-if="!isCampaign">
					{{formatCurrencyUnd(amount, '0,0')}}
				</div>
			</div>
			<div style="font-size: 12px; font-weight: bold; opacity: 0.42" v-if="!isDelivery">
				{{mcreate.format('MM/DD')}}
			</div>
			<div class="order" v-if="isDelivery && !isRedeemed">ORDER</div>
			<div style="font-size: 12px; font-weight: bold; opacity: 0.42" v-if="isDelivery && isRedeemed">ORDERED</div>
			<div style="font-size: 12px; font-weight: bold; opacity: 0.42" v-if="isDelivery && isRedeemed">
				{{mcreate.format('MM/DD')}}
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {get} from 'lodash';
  import {json} from 'overmind';
  import moment from 'moment';
  import numeral from 'numeral';
  // import $ from 'jquery';

  export default {
    props: ['item'],
    setup() {
      const data = reactive({
        isSent: false,
        isCampaign: false,
        isDebit: false,
        isFlute: false,
        isAccepted: false,
        isExpired: false,
        isPending: false,
        isDeclined: false,
        isRedeemed: false,
        user: {},
        mcreate: null,
        amount: null,
        remainderDrinks: null,
        isDelivery: false,
        itemLabel: null,
        itemLabelPlural: null,
        sign: '',
        get,
        numeral
      });

      const formatCurrencyUnd = function (price, format = '00') {
        const decimals = format.length;
        const dec = Math.floor(price);
        const und = (Number(Math.round(price + 'e' + decimals) + 'e-' + decimals) - dec) * 100;
        return numeral(und).format(format);
      };
      return {
        formatCurrencyUnd,
        ...toRefs(data)
      }
    },
    beforeMount() {
      const item = json(this.item);
      this.isSent = get(item, 'sentFrom.id', '') === this.state.currentUser.id;
      this.isCampaign = item.isCampaign;
      this.isDebit = !this.isCampaign && (item.usedOnTab || item.usedForGift || item.usedForSplit);
      this.isFlute = !this.isCampaign && !this.isDebit;
      this.isAccepted = item.isAccepted;
      this.isExpired = item.isExpired;
      this.isPending = !this.isAccepted && !this.isExpired && item.isPending;
      this.isDeclined = !this.isAccepted && !this.isExpired && !this.isPending;
      this.isRedeemed = item.isRedeemed;
      this.user = this.isSent ? item.sentTo : item.sentFrom;
      this.mcreate = moment(item.createdAt);
      this.amount = this.isDebit ? item.totalCost : item.dueToReceiver;
      this.remainderDrinks = item.numberOfItems - item.numberOfItemsRedeemed;
      this.isDelivery = !!(item.campaign && item.campaign.isDelivery);
      if (item.campaign && item.campaign.itemLabel && item.campaign.itemLabelPlural) {
        this.itemLabel = item.campaign.itemLabel;
        this.itemLabelPlural = item.campaign.itemLabelPlural;
      } else {
        this.itemLabel = 'Drink';
        this.itemLabelPlural = 'Drinks';
      }
      if (!this.isCampaign && this.isFlute && (!this.isSent || !this.isAccepted) && (!this.isPending && !this.isDeclined)) {
        this.sign = '+ ';
      } else if (!this.isCampaign && this.isFlute && this.isSent && this.isAccepted) {
        this.sign = '- ';
      } else if (this.isDebit) {
        this.sign = '- ';
      } else {
        this.sign = '';
      }
    }
  }
</script>

<style lang="css" scoped>
	.order {
		font-size: 15px;
		font-weight: bold;
		opacity: 0.42;
		padding: 4px 16px;
		border-width: 1px;
		border-color: black;
		border-radius: 20px;
		border-style: solid
	}
	.item-image {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		background-color: #DDDDDD
	}
	
	.desc {
		font-size: 12px;
		font-weight: 500;
		font-style: italic;
		opacity: 0.42
	}
	
	.wallet-price {
		position: absolute;
		right: 20px;
		bottom: 40px;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
</style>
